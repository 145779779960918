@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
  --bg-color: #f9faff;
}

/* Data table Designing */

/* Increase the size of checkboxes in DataTable columns */
.rdt_TableCol > input {
  transform: scale(1.6);
}

/* Increase the size of checkboxes in DataTable cells */
.rdt_TableCell > input {
  transform: scale(1.6);
}

/* Hide the default checkbox styles */
input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 0.3px solid rgb(215, 215, 216);
  background-color: var(--bg-color);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

/*Custom styles for the checked checkbox */
input[type='checkbox']:checked {
  background-color: var(--bg-color);
  border-color: #4fd1c5;
}

/* Display a checkmark when the checkbox is checked */
input[type='checkbox']:checked::before {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 800;
  color: #4fd1c5;
}

/* Styling for the scrollbar */
::-webkit-scrollbar {
  background-color: rgba(0, 189, 178, 0.15);
  border-radius: 30px;
  height: 10px;
  width: 10px;
  transform: translateX(-20px);
}
::-webkit-scrollbar-track {
  width: 80%;
}

/* Styling for the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #00bdb2;
  border-radius: 30px;
  box-sizing: content-box;
  height: 80%;
}

/* Additional styles for checkboxes in DataTable cells */
.rdt_TableCell > input[type='checkbox'] {
  border-radius: 3px;
}

/* Additional styles for checkboxes in DataTable columns */
.rdt_TableCol > input[type='checkbox'] {
  border-radius: 3px;
}

/* Styling for DataTable columns */
.rdt_TableCol {
  font-size: 14px;
  position: relative;
  color: #585858;
}

/* Adding a vertical divider line between DataTable columns */
.rdt_TableCol:after {
  content: '';
  position: absolute;
  height: 50%;
  width: 2px;
  right: 0;
}

/* colors */

body {
  background-color: var(--bg-color);
  font-family: 'Poppins', sans-serif;
}

.rdt_TableHead > div {
  background-color: var(--bg-color) !important;
}

.custom-data-table tr:hover {
  background-color: lightblue;
}

.megaFilterScroll::-webkit-scrollbar {
  display: none;
}

.online {
  position: relative;
}

.online::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.7rem;
  height: 0.7rem;
  z-index: 1;
  transform: translate(-25%, -25%);
  border-radius: 100%;
  background-color: #29bc52;
}

/* Data table hover color change  */

.kvgeKk:hover {
  border-bottom-color: #00bdb2 !important;
  outline-color: #00bdb2 !important;
}

.primary-button {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: -4px -4px 15px 0px rgba(0, 0, 0, 0.18) inset,
    3px 3px 12px 0px rgba(255, 255, 255, 0.2) inset;
  backdrop-filter: blur(5px);
  width: 84px;
  height: 41px;
  flex-shrink: 0;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.customFlex > div {
  flex: 1 1 200px;
  max-width: fit-content;
  max-height: fit-content;
}

.customFlex {
  flex-wrap: wrap;
}

.indicator {
  transition: 0.3s ease-in-out !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

/* drawer memo scrollbar width */
.memoScrollBar::-webkit-scrollbar {
  width: 6px;
}

/* make the prgressbar custom */

progress::-webkit-progress-value {
  background-color: #00bdb2;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
}
progress::-webkit-progress-bar {
  background-color: #9ffff9;
  border-radius: 50px;
}
progress {
  border-radius: 50px !important;
}

@media screen and (min-width: 250px) and (max-width: 1400px) {
  .kYTSru {
    height: 42px !important;
    padding-left: 10px !important;
    padding-right: 6px !important;
  }
}

.no-calendar::-webkit-calendar-picker-indicator {
  cursor: pointer;
  z-index: 10;
  opacity: 0.2;
  filter: invert(0.9);
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .widthfixx {
    width: 242px !important;
  }
}

@media screen and (min-width: 1320px) and (max-width: 1359px) {
  .DataTable {
    width: 820px !important;
  }
}
@media screen and (min-width: 1360px) and (max-width: 1400px) {
  .DataTable {
    width: 852px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1460px) {
  .DataTableMain {
    width: 1058px !important;
  }
  .DataTable {
    width: 880px !important;
  }
}

@media screen and (min-width: 1461px) and (max-width: 1535px) {
  .DataTableMain {
    width: 1060px !important;
  }
  .DataTable {
    width: 930px !important;
  }
}

.otp-text input {
  text-align: center;
}

.otp-text input:focus {
  border: none !important;
}

.static,
.editable {
  width: 100%;
  border-collapse: collapse;
}
.static th,
.static td,
.editable th,
.editable td {
  border-left: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}
.static tbody > tr > td:first-child,
.static thead > tr > th:first-child,
.editable tbody > tr > td:first-child,
.editable thead > tr > th:first-child {
  width: 150px;
}
.static thead > tr,
.static thead > tr > th:first-child,
.editable thead > tr,
.editable thead > tr > th:first-child {
  border: none !important;
}
.static tr,
.editable tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.static thead > tr:nth-child(2) > th,
.editable thead > tr:nth-child(2) > th {
  background-color: #e6f7f9;
}

.editable .empty {
  border: 1px solid red !important;
}

.switch-container {
  display: inline-block;
  position: relative;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.switch-checkbox:checked + .switch-label {
  background-color: #00bdb2;
}

.switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: white;
  transition: 0.3s;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: 26px;
}
